<template>
  <div
    class="min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-12 shadow-lg items-center"
  >
    <div class="bg-white w-fullrounded-lg shadow">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-2">
        <div class="flex">
          <router-link
            :class="$colors.primary"
            class="rounded-full flex justify-center items-center w-8 h-8 mt-1 mr-3"
            to="/roles"
          >
            <i class="material-icons mx-2">chevron_left</i>
          </router-link>
          <div v-if="!formData._id" class="text-2xl font-bold text-green-500 ">
          {{$t('new_role')}}
          </div>
          <div v-else class="text-2xl font-bold text-green-500 ">{{$t('edit_role')}}</div>
        </div>
      </div>

      <div class="">
        <div class="bg-white rounded-lg p-6 pt-2">
          <div class="grid lg:grid-cols-2 gap-6">
            <div
              class="border hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="name" class="bg-white text-gray-700 px-1"
                    >{{$t('name')}} <span :class="$colors.required">*</span>
                  </label>
                </p>
              </div>
              <p>
                <input
                  id="name"
                  autocomplete="false"
                  tabindex="0"
                  type="text"
                  v-model="formData.name"
                  class="py-2 text-xs px-2 outline-none block h-full w-full"
                />
              </p>
            </div>

            <!-- <div
              class="border h-10 focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="name" class="bg-white text-gray-700 px-1"
                    >Type *</label
                  >
                </p>
              </div>
              <p>
                <select
                  id="type"
                  name="type"
                  autocomplete="type"
                  v-model="formData.type"
                  class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white"
                >
                  <option selected disabled :value="null">Type</option>
                  <option v-for="type in types" :key="type" :value="type">
                    {{ type }}
                  </option>
                </select>
              </p>
            </div> -->

            <div
              class="border hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="description" class="bg-white text-gray-700 px-1"
                    >{{$t('description')}}
                  </label>
                </p>
              </div>
              <p>
                <input
                  id="description"
                  autocomplete="false"
                  tabindex="0"
                  type="text"
                  v-model="formData.description"
                  class="py-2 text-xs px-2 outline-none block h-full w-full"
                />
              </p>
            </div>
          </div>

          <div
            class="border hover:border-green-500  mt-4 px-4 w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
          >
            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
              <p>
                <label for="description" class="bg-white text-gray-700 px-1"
                  >{{$t('permissions')}} <span :class="$colors.required">*</span></label
                >
              </p>
            </div>
            <div class="">
              <div class="w-full mt-2">
                <table class="table w-full text-center">
                  <thead class="bg-gray-100 text-black">
                    <tr>
                      <th
                        class="px-5 py-2 border-gray-200 text-left text-xs font-semibold text-gray-700 capitalize tracking-wider"
                      >
                        {{$t('model')}}
                      </th>
                      <th
                        class="px-5 py-2 border-gray-200 text-left text-xs font-semibold text-gray-700 capitalize tracking-wider"
                      >
                        {{$t('create')}}
                      </th>
                      <th
                        class="px-5 py-2 border-gray-200 text-left text-xs font-semibold text-gray-700 capitalize tracking-wider"
                      >
                        {{$t('read')}}
                      </th>
                      <th
                        class="px-5 py-2 border-gray-200 text-left text-xs font-semibold text-gray-700 capitalize tracking-wider"
                      >
                        {{$t('update')}}
                      </th>
                      <th
                        class="px-5 py-2 border-gray-200 text-left text-xs font-semibold text-gray-700 capitalize tracking-wider"
                      >
                        {{$t('delete')}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(el, index) in modules"
                      :key="index"
                      :class="index % 2 == 0 ? 'bg-gray-100' : ''"
                    >
                      <td
                        class="px-5 py-2 pt-3 text-left border-gray-200 bg-white text-sm"
                      >
                        <p class="text-gray-700 capitalize">
                          {{ el.model }}
                        </p>
                      </td>
                      <td
                        class="px-5 py-2 pt-3 text-left border-gray-200 bg-white text-sm"
                      >
                        <p class="text-gray-600 ml-3 text-semibold capitalize">
                          <label
                            class="inline-flex text-sm items-center capitalize"
                          >
                            <input
                              v-model="el.access.create"
                              type="checkbox"
                              class="form-checkbox border-gray-500 h-4 w-4 text-green-500 "
                            />
                          </label>
                        </p>
                      </td>
                      <td
                        class="px-5 py-2 pt-3 text-left border-gray-200 bg-white text-sm"
                      >
                        <p class="text-gray-600 ml-2 text-semibold capitalize">
                          <label
                            class="inline-flex text-sm items-center capitalize"
                          >
                            <input
                              v-model="el.access.read"
                              type="checkbox"
                              class="form-checkbox border-gray-500 h-4 w-4 text-green-500 "
                            />
                          </label>
                        </p>
                      </td>
                      <td
                        class="px-5 py-2 pt-3 text-left border-gray-200 bg-white text-sm"
                      >
                        <p class="text-gray-600 ml-3 text-semibold capitalize">
                          <label
                            class="inline-flex text-sm items-center capitalize"
                          >
                            <input
                              v-model="el.access.update"
                              type="checkbox"
                              class="form-checkbox border-gray-500 h-4 w-4 text-green-500 "
                            />
                          </label>
                        </p>
                      </td>
                      <td
                        class="px-5 py-2 pt-3 text-left border-gray-200 bg-white text-sm"
                      >
                        <p class="text-gray-600 ml-3 text-semibold capitalize">
                          <label
                            class="inline-flex text-sm items-center capitalize"
                          >
                            <input
                              v-model="el.access.delete"
                              type="checkbox"
                              class="form-checkbox border-gray-500 h-4 w-4 text-green-500 "
                            />
                          </label>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="mt-6 pt-3 flex justify-center">
            <button
              class="rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600  hover:shadow-inner hover:bg-green-700  transition-all duration-300"
              @click="save"
            >
              {{$t('save')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {},
      modules: [],
      types: [
        "Seller",
        "TeleConsultant",
        "NRP",
        "StockManager",
        "Courier",
        "Accountant",
        "Return",
        "Admin",
        "CancelledOrder",
        'ToRemind'
      ],
    };
  },

  async mounted() {
    this.dataToEdit();
    this.setModules();
  },
  methods: {
    async save() {
      this.formData.permissions = this.modules;
      //console.log('oooooooooooooooook1',this.formData)
      if (this.formData._id) {
        const data = await this.$server.update("roles", this.formData);
        if (data._id) alert(this.$t('role_updated'), "success");
      } else {
        console.log('oooooooooooooooook')
        if (this.checkData()) {
            
          const data = await this.$server.create("roles", this.formData);
          console.log('oooooooooooooooook1',data)
          if (data._id) {
             alert(this.$t('role_created'), "success");
              //console.log(data);
                this.formData = {};
                this.setModules();
            if (localStorage.getItem("User_email") != null) {
                 localStorage.setItem('Role', JSON.stringify(data));
                 this.$router.push('/users/new');
             }
          
           
           
          }
        } else alert(this.$t('all_required_field'), "warning");
      }
    },
    checkData() {
      if (this.formData.name) {
        return true;
      } else {
        return false;
      }
    },

    setModules() {
      this.modules = [
        {
          model: "categories",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "expeditions",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "orders",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "payments",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "products",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "roles",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "shippings",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "status",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "statusHistories",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "stockHistories",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "shippingsHistories",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "subscribers",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "users",
          access: { create: false, read: false, update: false, delete: false },
        },
         {
          model: "userHistories",
          access: { create: false, read: false, update: false, delete: false ,watch: false },
        },
        {
          model: "zones",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "teams",
          access: { create: false, read: false, update: false, delete: false },
        },
         {
          model: "settings",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "analytics",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "dashboard",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "warehouses",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "charges",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "senders",
          access: { create: false, read: false, update: false, delete: false },
        },
         {
          model: "sourcings",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "sourcingsHistories",
          access: { create: false, read: false, update: false, delete: false },
        },
        {
          model: "affiliates",
          access: { create: false, read: false, update: false, delete: false },
        },

        
      ];
    },
    async dataToEdit() {
      if (this.$route.params.id) {
        const editData = await this.$server.get("roles", {
          id: this.$route.params.id,
        });
        this.formData = editData.content;
        this.modules = this.formData.permissions;
        this.AddelementArray( {model: "warehouses",access: { create: false, read: false, update: false, delete: false }},this.modules);
        this.AddelementArray( {model: "shippingsHistories",access: { create: false, read: false, update: false, delete: false }},this.modules);
        this.AddelementArray( {model: "settings",access: { create: false, read: false, update: false, delete: false }},this.modules);
        this.AddelementArray( {model: "analytics",access: { create: false, read: false, update: false, delete: false }},this.modules);
        this.AddelementArray( {model: "userHistories",access: { create: false, read: false, update: false, delete: false ,watch: false }},this.modules);
        this.AddelementArray( {model: "dashboard",access: { create: false, read: false, update: false, delete: false }},this.modules);
        this.AddelementArray( {model: "charges",access: { create: false, read: false, update: false, delete: false }},this.modules);
        this.AddelementArray({model: 'senders',access: {create: false,read: false,update: false,delete: false,},},this.modules,)
        this.AddelementArray( {model: "sourcings",access: { create: false, read: false, update: false, delete: false }},this.modules);
        this.AddelementArray( {model: "sourcingsHistories",access: { create: false, read: false, update: false, delete: false }},this.modules);
        this.AddelementArray( {model: "affiliates",access: { create: false, read: false, update: false, delete: false }},this.modules);
        //console.log(this.formData);
      }
    },
     AddelementArray(element,array){
        Array.prototype.inArray = function(comparer) { 
          for(var i=0; i < this.length; i++) { 
              if(comparer(this[i])) return true; 
          }
          return false; 
        }; 

        // adds an element to the array if it does not already exist using a comparer 
        // function
        Array.prototype.pushIfNotExist = function(element, comparer) { 
            if (!this.inArray(comparer)) {
                this.push(element);
            }
        }; 
        array.pushIfNotExist(element, function(e) { 
            return e.model === element.model; 
        });
    },
  },
};
</script>
<style >

.icon::after {
  content: "";
  display: block;
  position: absolute;
  border-top: 23px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 12px solid #3182ce;
  left: 100%;
  top: 0;
}
</style>